import {Component, Input, OnInit} from '@angular/core';
import {Project} from '../../types/project';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  faFileImport = faFileImport;

  @Input()
  project: Project;

  constructor() { }

  ngOnInit(): void {
  }

  getProjectStatus(): string {
    if (this.project.status === 'open') {
      return 'Open';
    }
    return 'Closed';
  }

}
