<div class="login-control">
  <div class="text-center logo">
    <img src="../../../assets/logo.jpg" alt="Access Gates and Shutters Logo" />
    <span>Project Reporting and Status Interface</span>
  </div>
  <form
    name="form"
    (ngSubmit)="f.form.valid && login()"
    #f="ngForm"
    novalidate>
    <div class="input-group">
      <span class="input-group-label"><fa-icon [icon]="faUser"></fa-icon></span>
      <input class="input-group-field" type="text" placeholder="Username" name="username" [(ngModel)]="form.username" required #username="ngModel">
    </div>
    <div class="input-group">
      <span class="input-group-label"><fa-icon [icon]="faLock"></fa-icon></span>
      <input class="input-group-field" type="password" placeholder="Password" name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel">
    </div>
    <button class="button expanded login">Login</button>
  </form>
</div>
