import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { faFolder, faCamera } from '@fortawesome/free-solid-svg-icons';
import {Project} from '../../types/project';
import {ProjectsService} from '../../services/projects/projects.service';
import {ToastrService} from 'ngx-toastr';
import {FilesService} from '../../services/files/files.service';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit {

  @Output()
  projectCreated: EventEmitter<Project> = new EventEmitter<Project>();

  uploadedFiles: FileList;

  faFolder = faFolder;
  faCamera = faCamera;

  newProject: Project = new Project();

  constructor(private projectsService: ProjectsService,
              private toastrService: ToastrService,
              private filesService: FilesService) { }

  ngOnInit(): void {
    this.newProject.status = 'open';
  }

  addProject(): void {
    this.projectsService.addProject(this.newProject)
      .subscribe(project => {
        this.projectCreated.emit(project);
        this.newProject = new Project();
        this.toastrService.success('Project created successfully.', 'Success');
      });
  }

  fileChange(element): void  {
    this.uploadedFiles = element.target.files;
  }

  upload() {
    const formData = new FormData();

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      formData.append('uploads[]', this.uploadedFiles[i], this.uploadedFiles[i].name);
    }

    this.filesService.upload(formData)
      .subscribe(response => {
        this.uploadedFiles = new FileList();
      });
  }
}
