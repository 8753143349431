<div class="project-list-item" (click)="selectProject()" [ngClass]="{'selected': selected}">
  <div class="grid-x">
    <div class="cell medium-3">
      {{ project.jobReference }}
    </div>
    <div class="cell medium-6">
      {{ project.customer }}
    </div>
    <div class="cell medium-3">
      {{ project.dateAttended | date: 'MMM d y' }}
    </div>
  </div>
</div>
