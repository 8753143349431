<div class="project section">
  <h3>Financial</h3>
  <div class="grid-x grid-margin-x">
    <div class="cell medium-12">
      <app-confirmation-box title="Has the job been invoiced" [checked]="project.jobInvoiced" (checkChange)="project.jobInvoiced = $event"></app-confirmation-box>
    </div>
    <div class="cell medium-6">
      <input type="text" placeholder="Invoice No:" [(ngModel)]="project.invoiceNumber">
    </div>
    <div class="cell medium-6">
      <app-confirmation-box title="Invoice paid" [checked]="project.invoicePaid" (checkChange)="project.invoicePaid = $event"></app-confirmation-box>
    </div>
  </div>
</div>
