import { Component, OnInit } from '@angular/core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {TokenStorageService} from '../../services/token-storage/token-storage.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  faUser = faUser;
  isLoggedIn = false;
  name: string;

  constructor(private router: Router,
              private tokenStorageService: TokenStorageService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.name = user.name;
    }
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.toastrService.success('You have successfully logged out.', 'Goodbye');
    this.router.navigate(['login']);
  }

}
