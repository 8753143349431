import { Component, OnInit } from '@angular/core';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {TokenStorageService} from '../../services/token-storage/token-storage.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  faUser = faUser;
  faLock = faLock;

  form: any = {};

  constructor(private router: Router,
              private authService: AuthenticationService,
              private tokenStorageService: TokenStorageService,
              private toastrService: ToastrService) { }

  ngOnInit() {
    if (this.tokenStorageService.getToken()) {
      this.router.navigate(['projects']);
    }
  }

  login(): void {
    this.authService.login(this.form)
      .subscribe(data => {
        this.tokenStorageService.saveToken(data.accessToken);
        this.tokenStorageService.saveUser(data);
        this.toastrService.success(`Welcome back ${data.name}!`, 'Welcome');
        this.router.navigate(['projects']);
      });
  }
}
