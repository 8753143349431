import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {Project} from '../../types/project';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {ProjectsService} from '../../services/projects/projects.service';

@Component({
  selector: 'app-project-search',
  templateUrl: './project-search.component.html',
  styleUrls: ['./project-search.component.scss']
})
export class ProjectSearchComponent implements OnInit {

  faSearch = faSearch;

  projects$: Observable<Project[]>;
  private searchTerms = new Subject<string>();

  searchValue = '';

  @Output()
  projectSelected: EventEmitter<Project> = new EventEmitter<Project>();

  constructor(private projectsService: ProjectsService) { }

  search(term: string): void {
    this.searchTerms.next(term);
  }

  ngOnInit(): void {
    this.projects$ = this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) => this.projectsService.searchProjects(term))
    );
  }

  selectProject(project: Project): void {
    this.projectSelected.emit(project);
    this.searchValue = '';
  }

}
