import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  private filesUrl = 'http://api.accessgatesandshutters.co.uk/api/files';

  constructor(private http: HttpClient) { }

  upload(formData: FormData): Observable<any> {
    return this.http.post(this.filesUrl + '/upload', formData);
  }
}
