import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Project} from '../../types/project';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {
  @Input()
  projects: Project[];

  @Output()
  projectSelected: EventEmitter<Project> = new EventEmitter<Project>();

  @Input()
  selectedProjectId: number;

  constructor() { }

  ngOnInit(): void {
  }

  selectProject(project: Project): void {
    this.projectSelected.emit(project);
  }

  selected(project: Project): boolean {
    return this.selectedProjectId && this.selectedProjectId === project.id;
  }

}
