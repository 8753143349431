<div class="project section">
  <h3>Project Details</h3><div class="project-status" [ngClass]="{'open': project.status === 'open', 'closed': project.status === 'closed'}">Status: {{ getProjectStatus() }}</div><button class="button"><fa-icon [icon]="faFileImport"></fa-icon> Generate Report</button>
  <div class="grid-x grid-margin-x">
    <div class="cell medium-8">
      <label>Customer</label>
      <input type="text" placeholder="Customer" class="bold" [(ngModel)]="project.customer">
    </div>
    <div class="cell medium-4">
      <label>Job Reference</label>
      <input type="text" placeholder="Job Reference" class="bold" [(ngModel)]="project.jobReference">
    </div>

    <div class="cell medium-8">
      <label>Address</label>
      <input type="text" placeholder="Address" [(ngModel)]="project.address">
    </div>
    <div class="cell medium-4">
      <label>Contact</label>
      <input type="text" placeholder="Contact" [(ngModel)]="project.contact">
    </div>

    <div class="cell medium-8">
      <label>Project Title</label>
      <input type="text" placeholder="Project Title" [(ngModel)]="project.title">
    </div>
    <div class="cell medium-4">
      <label>Date Attended</label>
      <input type="date" placeholder="Date Attended" [ngModel]="project.dateAttended | date: 'yyyy-MM-dd'" (ngModelChange)="project.dateAttended = $event">
    </div>
  </div>
</div>
