<div class="header">
  <app-logout></app-logout>
  <div>
    <div>
      <div class="grid-container full project-page">
        <div class="grid-x">
          <div class="cell medium-10 medium-offset-1">
            <h1>Work in Progress</h1>
            <span>Project Reporting &amp; Status Interface</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <img src="../../../assets/logo.png" />
</div>

