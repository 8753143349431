import { Component, OnInit } from '@angular/core';
import {Project} from '../../types/project';
import {Observable} from 'rxjs';
import {ProjectsService} from '../../services/projects/projects.service';

@Component({
  selector: 'app-invoiced-projects-page',
  templateUrl: './invoiced-projects-page.component.html',
  styleUrls: ['./invoiced-projects-page.component.scss']
})
export class InvoicedProjectsPageComponent implements OnInit {
  projects$: Observable<Project[]>;

  constructor(private projectsService: ProjectsService) { }

  ngOnInit(): void {
    this.projects$ = this.projectsService.getInvoicedProjects();
  }

}
