import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faAngleDown, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-collapsible-text-box',
  templateUrl: './collapsible-text-box.component.html',
  styleUrls: ['./collapsible-text-box.component.scss']
})
export class CollapsibleTextBoxComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  content: string;

  @Output()
  contentChanged: EventEmitter<string> = new EventEmitter<string>();

  faAngleDown = faAngleDown;
  faTimes = faTimes;
  icon = faAngleDown;

  expanded = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.icon = faTimes;
    } else {
      this.icon = faAngleDown;
    }
  }

  contentUpdated(content): void {
    this.contentChanged.emit(content);
  }

}
