import { Component, OnInit } from '@angular/core';
import {ProjectsService} from '../../services/projects/projects.service';
import {Project} from '../../types/project';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss']
})
export class ProjectsPageComponent implements OnInit {
  projects: any[];
  selectedProject = null;
  selectedProjectId = null;
  showCloseProject: boolean;

  constructor(public projectsService: ProjectsService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.projectsService.getProjects()
      .subscribe(projects => {
        this.projects = projects;
      });
  }

  toggleProjectSelect(project): void {
    if (this.selectedProject && this.selectedProject.id === project.id) {
      this.selectedProject = null;
      this.selectedProjectId = null;
    } else {
      this.selectedProject = project;
      this.selectedProjectId = project.id;
      this.showCloseProject = project.status == 'open';
    }
  }

  saveProject(): void {
    this.projectsService.updateProject(this.selectedProject)
      .subscribe(_ => {
        this.toggleProjectSelect(this.selectedProject);
        this.toastrService.success('Project saved successfully', 'Success');
      });
  }

  projectCreated(project: Project): void {
    this.projectsService.getProjects()
      .subscribe(projects => {
        this.projects = projects;
        this.selectedProject = project;
        this.selectedProjectId = project.id;
      });
  }

  cancel(): void {
    this.toggleProjectSelect(this.selectedProject);
  }

  closeProject(): void {
    this.selectedProject.status = 'closed';
    this.projectsService.updateProject(this.selectedProject)
      .subscribe(_ => {
        this.toggleProjectSelect(this.selectedProject);
      });
  }

  openProject(): void {
    this.selectedProject.status = 'open';
    this.projectsService.updateProject(this.selectedProject)
      .subscribe(_ => {
        this.toggleProjectSelect(this.selectedProject);
      });
  }
}
