<div class="project-search">
  <div class="input-group">
    <input #searchBox class="input-group-field" type="text" placeholder="Search" (input)="search(searchBox.value)" [value]="searchValue">
    <span class="input-group-label"><fa-icon [icon]="faSearch"></fa-icon></span>
  </div>
  <div *ngIf="projects$ | async as projects">
    <ul class="search-results shadow-x-low shadow-high" *ngIf="projects.length > 0">
      <li *ngFor="let project of projects" (click)="selectProject(project)">{{ project.customer }}</li>
    </ul>
  </div>
</div>

