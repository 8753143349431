import { Component, OnInit } from '@angular/core';
import {Project} from '../../types/project';
import {ProjectsService} from '../../services/projects/projects.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-project-details-page',
  templateUrl: './project-details-page.component.html',
  styleUrls: ['./project-details-page.component.scss']
})
export class ProjectDetailsPageComponent implements OnInit {
  project: Project;

  constructor(private route: ActivatedRoute,
              private projectsService: ProjectsService) { }

  ngOnInit(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectsService.getProjectById(id)
      .subscribe(project => {
        this.project = project;
      });
  }

}
