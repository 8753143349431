<div class="project section">
  <h3>Project Status</h3>
  <div class="grid-x grid-margin-x">
    <div class="cell medium-12">
      <app-collapsible-text-box title="Job Details" [content]="project.jobDetails" (contentChanged)="project.jobDetails = $event"></app-collapsible-text-box>
    </div>
    <div class="cell medium-12">
      <app-collapsible-text-box title="Supplier Details" [content]="project.supplierDetails"></app-collapsible-text-box>
    </div>
    <div class="cell medium-12">
      <app-collapsible-text-box title="Job Re-attended &amp; Details" [content]="project.jobReattendedDetails"></app-collapsible-text-box>
    </div>
    <div class="cell medium-12">
      <app-collapsible-text-box title="Any Further Works" [content]="project.furtherWorks"></app-collapsible-text-box>
    </div>
  </div>
</div>
