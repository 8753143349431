import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProjectsPageComponent} from './pages/projects-page/projects-page.component';
import {LoginPageComponent} from './pages/login-page/login-page.component';
import {AuthGuard} from './guards/auth/auth.guard';
import {ProjectDetailsPageComponent} from './pages/project-details-page/project-details-page.component';
import {InvoicedProjectsPageComponent} from './pages/invoiced-projects-page/invoiced-projects-page.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'projects',
    component: ProjectsPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'projects/invoiced/report',
    component: InvoicedProjectsPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'projects/:id/report',
    component: ProjectDetailsPageComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
