import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit {

  faCheck = faCheck;
  faTimes = faTimes;

  @Input()
  checked: boolean;

  @Input()
  title: string;

  @Output()
  checkChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  check(): void {
    this.checked = true;
    this.checkChange.emit(this.checked);
  }

  uncheck(): void {
    this.checked = false;
    this.checkChange.emit(this.checked);
  }

}
