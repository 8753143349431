import {Component, Input, OnInit} from '@angular/core';
import {Project} from '../../types/project';

@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss']
})
export class ProjectStatusComponent implements OnInit {
  @Input()
  project: Project;

  constructor() { }

  ngOnInit(): void {
  }

}
