import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Project} from '../../../types/project';


@Component({
  selector: 'app-project-list-item',
  templateUrl: './project-list-item.component.html',
  styleUrls: ['./project-list-item.component.scss']
})
export class ProjectListItemComponent implements OnInit {
  @Input()
  project: Project;

  @Input()
  selected = false;

  @Output()
  projectSelected: EventEmitter<Project> = new EventEmitter<Project>();

  constructor() { }

  ngOnInit(): void {
  }

  selectProject(): void {
    this.projectSelected.emit(this.project);
  }
}
